<template>
  <el-main class="my-card">
    <div class="flex">
      <div></div>
      <div class="buttons">
        <el-button type="primary" icon="el-icon-refresh-right" size="medium" @click="fetchOrders(true)"/>
        <el-button type="primary" size="medium" @click="exportHandler">导出Excel表格</el-button>
      </div>
    </div>
    <el-card shadow="never">
      <el-form ref="formData" size="medium" :model="formData" label-width="80px">
        <div class="flex-raw">
          <el-form-item label="标题" prop="title">
            <el-input v-model.trim="formData.title" clearable/>
          </el-form-item>
          <el-form-item label="订单编号" prop="oid">
            <el-input v-model.trim="formData.oid" clearable/>
          </el-form-item>
          <el-form-item label="结算状态" prop="settle_status">
            <el-select v-model="formData.settle_status" clearable>
              <el-option v-for="(item,index) in records.settle_status_list" :label="item.name" :value="item.value" :key="index"/>
            </el-select>
          </el-form-item>
          <el-form-item label="订单状态" prop="status">
            <el-select v-model="formData.status" clearable>
              <el-option v-for="(item,index) in records.status_list" :label="item.name" :value="item.value" :key="index"/>
            </el-select>
          </el-form-item>
        </div>
        <div class="flex-raw">
          <el-form-item label="来源" prop="source">
            <el-select v-model="formData.source" clearable>
              <el-option v-for="(item,index) in records.source_list" :label="item.name" :value="item.value" :key="index"/>
            </el-select>
          </el-form-item>
          <el-form-item label="下单时间" prop="robot_type">
<!--            <el-date-picker-->
<!--                v-model="formData.dateRange"-->
<!--                type="datetimerange"-->
<!--                format="yyyy-MM-dd HH:mm:ss"-->
<!--                value-format="yyyy-MM-dd"-->
<!--                unlink-panels-->
<!--                range-separator="至"-->
<!--                start-placeholder="开始日期"-->
<!--                end-placeholder="结束日期"-->
<!--                :picker-options="pickerOptions">-->
<!--            </el-date-picker>-->
            <CustomChoiceDatePicker
                date-type="datetimerange"
                @handelChange="handleTime"
            />
          </el-form-item>
          <el-form-item label="">
            <el-button type="primary" size="medium" @click="onSubmit">搜索</el-button>
            <el-button size="medium" @click="_reset">重置</el-button>
          </el-form-item>
          <el-form-item label="">
          </el-form-item>
        </div>
      </el-form>
      <el-divider></el-divider>
      <el-table border :data="records.list" style="width: 100%">
        <el-table-column label="标题" align="center" min-width="150" prop="title">
        </el-table-column>
        <el-table-column label="订单编号" align="center" min-width="220" prop="oid">
        </el-table-column>
        <el-table-column label="来源" align="center" min-width="150" prop="source"/>
        <el-table-column label="付款金额" align="center" min-width="150">
          <template slot-scope="scope">
            <span style="color: red">¥{{ scope.row.amount }}</span>
          </template>
        </el-table-column>
        <el-table-column label="平台抽成" align="center" min-width="150">
          <template slot-scope="scope">
            <span style="color: red">¥{{ scope.row.platform_fee }}</span>
          </template>
        </el-table-column>
        <el-table-column label="预估佣金" align="center" min-width="120">
          <template scope="scope">
            <span style="color: red">{{ scope.row.commission }}</span>
          </template>
        </el-table-column>
        <el-table-column label="实际佣金" align="center" min-width="120">
          <template scope="scope">
            <span style="color: red">{{ scope.row.real_commission }}</span>
          </template>
        </el-table-column>
        <el-table-column label="收入比例" align="center" min-width="180" prop="commission_bili">
        </el-table-column>
        <el-table-column label="订单状态" align="center" min-width="150" prop="status">
        </el-table-column>
        <el-table-column label="下单时间" align="center" min-width="180" prop="create_time">
        </el-table-column>
        <el-table-column label="联盟结算时间" align="center" min-width="180" prop="platform_settle_time">
        </el-table-column>
        <el-table-column label="结算时间" align="center" min-width="180" prop="settle_time">
        </el-table-column>
        <el-table-column label="结算状态" align="center" min-width="150" prop="settle_status">
        </el-table-column>
        <!--        <el-table-column label="操作" align="center" min-width="150" fixed="right">-->
        <!--          <template scope="scope">-->
        <!--            订单详情-->
        <!--            <span @click="handlerDetail(scope.row)"></span>-->
        <!--            &lt;!&ndash;              <span :row="scope.row"></span>&ndash;&gt;-->
        <!--            &lt;!&ndash;              <CustomButton tip-text="编辑" is-svg icon="edit" @onClick="handlerDetail(scope.row, scope.$index)"/>&ndash;&gt;-->
        <!--          </template>-->
        <!--        </el-table-column>-->
      </el-table>
      <el-footer class="el-pagination-footer">
        <el-pagination :current-page="formData.page"
                       :page-sizes="[ 5, 10, 15, 20, 30, 50, 100, 200]"
                       :page-size="formData.size"
                       layout="total, prev, pager, next, sizes, jumper"
                       :total="total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange">
        </el-pagination>
      </el-footer>
    </el-card>
  </el-main>
</template>

<script>
import request from "@/utils/request";
// import CustomButton from '@/components/CustomButton'
import pickerOptions from '@/mixins/PickerOptions'
import CustomChoiceDatePicker from '@/components/CustomChoiceDatePicker/index'

export default {
  mixins: [pickerOptions],
  name: "order-index",
  components: {
    // CustomButton,
    // HeadTitle
    CustomChoiceDatePicker
  },
  props: {
    type: String,
  },
  data() {
    return {
      records: {
        list: []
      },
      total: 0,
      formData: {
        dateRange: [],
        "p": 1,
        "size": 10,
        "start_time": "",//下单时间开始区间
        "end_time": "",//下单时间结束区间
        "ord_type": "",//订单类型
        "video_type": "",//来源
        "status": "",//订单状态
        "settle_status": "",//结算状态
        "oid": ""//订单号
      }
    }
  },
  mounted() {
    this.fetchOrders()
  },
  methods: {
    exportHandler() {
      let num = 0
      num = Math.ceil(this.total / 3000) // 向上取整， 有小数点就取整
      if (num === 0) {
        return this.$message.info('没有查到内容，请重新选择')
      }
      this.$confirm(`一共有${this.total}条订单数据，确定要下载吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        const loading = this.$loading({lock: true, text: '正在准备下载...', spinner: 'el-icon-loading', background: 'rgba(255, 255, 255, 0.7)'})
        let params = {...this.formData}
        delete params.dateRange
        params.size = params.size + ''
        for (let i = 1; i <= num; i++) {
          params.p = i.toString()
          request({
            url: `/api/admin/${this.type}/order_output`,
            method: 'POST',
            data: params
          }).then(res => {
            this.downloadFileByBase64(res.file, res.filename, loading)
          }).catch(() => {
            loading.close()
          })
        }
      }).catch(() => {

      })

    },
    // 导出订单
    dataURLtoBlob(dataUrl) {
      let arr = dataUrl.split(',')
      let mime = arr[0].match(/:(.*?);/)[1]
      let bstr = atob(arr[1])
      let n = bstr.length
      let u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], {type: mime})
    },
    downloadFile(url, name = "What's the fuvk", event) {
      const a = document.createElement('a')
      a.setAttribute('href', url)
      a.setAttribute('download', name)
      a.setAttribute('target', '_blank')
      const clickEvent = document.createEvent('MouseEvents')
      clickEvent.initEvent('click', true, true)
      a.dispatchEvent(clickEvent)
      event.close()
    },
    async downloadFileByBase64(base64, name, event) {
      const myBlob = this.dataURLtoBlob(base64)
      const myUrl = URL.createObjectURL(myBlob)
      this.downloadFile(myUrl, name, event)
    },
    handlerDetail() {
    },
    handleSizeChange(val) {
      this.formData.size = val
      this.fetchOrders()
    },
    handleCurrentChange(val) {
      this.formData.p = val
      this.fetchOrders()
    },
    onSubmit() {
      this.formData.p = 1
      this.fetchOrders()
    },
    _reset() {
      this.$refs.formData.resetFields()
    },
    handleTime(val) {
      if (val && val.length === 2) {
        this.formData.start_time = val[0]
        this.formData.end_time = val[1]
      } else {
        this.formData.start_time = ''
        this.formData.end_time = ''
      }
    },
    fetchOrders(isRefresh = false) {
      if (isRefresh) {
        this.$message.success('刷新成功')
      }
      let params = {...this.formData}
      delete params.dateRange
      params.p = params.p + ''
      params.size = params.size + ''
      request({
        url: `/api/admin/${this.type}/order_list`,
        method: 'POST',
        data: params
      }).then(res => {
        this.total = res.total;
        this.records = res
      })
    }

  }
}
</script>

<style scoped lang="scss">
.my-card {
  padding-left: 0px;
  padding-right: 0;
}

.el-pagination-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

::v-deep .el-range-separator {
  width: 8% !important;
}

.flex-raw {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .el-form-item {
    width: 370px;

    .el-input {
      width: 100%;
    }

    .el-select {
      width: 100%;
    }

    .el-input__inner {
      width: 100%;
    }
  }
}

</style>
