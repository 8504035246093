<template>
  <el-main class="my-card">
    <div class="flex">
      <HeadTitle title="订单列表"/>
    </div>
    <el-card shadow="never">
      <el-tabs v-model="activeName">
        <el-tab-pane v-for="item in cates"  :key="item.type" :label="item.name" :name="item.type">
          <OrderList :type="item.type" />
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </el-main>
</template>

<script>
import HeadTitle from "@/components/head-title.vue";
import OrderList from './order-list.vue'
export default {
  name: "order-index",
  components: {
    HeadTitle,
    OrderList,
  },
  data() {
    return {
      activeName: 'playlet',
      cates: [
        {name: '短剧分销', type: 'playlet'},
        {name: '在线点餐', type: 'hw'},
        {name: 'cps商城', type: 'guide'},
        {name: '生活订单', type: 'life'}
      ]
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style scoped lang="scss">

</style>
